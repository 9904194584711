import React from "react";
import { Link } from "react-router-dom";
// import paymentHandler from "../one-to-one/OnePayment";

function Intro() {
  return (
    <>
     <section className="relative isolate overflow-hidden px-6 lg:overflow-visible lg:px-0 max-w-7xl m-auto h-full">
  <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 lg:px-8 py-12 sm:py-24 lg:max-w-7xl">
    <div className="relative isolate  ">
      <div
        className="absolute inset-x-0 -top-10 -z-10 transform-gpu overflow-hidden blur-3xl"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#F97316] to-[#54D6BB] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        ></div>
      </div>
      <div className="mx-auto max-w-2xl text-center">
        <div className="sm:mb-8 sm:flex sm:justify-center">
          <div className="relative rounded-full px-3 py-1 text-sm leading-6 mb-10 sm:mb-0">
            <Link
              className="font-bold text-orange-600 relative z-10 animate-linkglow sm:text-2xl text-xl rounded-full px-5 py-3"
              to="/PaymentPage"
            >
              <span className="absolute inset-0" aria-hidden="true"></span>
              Get Access <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        </div>
        <div className="text-center z-50">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Welcome To 'Confident Orators'
          </h1>
          <p className="sm:mt-6 mt-3 text-lg sm:leading-8 leading-6 text-gray-600">
            Master your Oratory skills with Our Transformational Course: Confident Orators! Learn to captivate your audience with ease, following in the footsteps of great speakers.
          </p>
        </div>
      </div>
      <div
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
        aria-hidden="true"
      >
        <div
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#F97316] to-[#54D6BB] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        ></div>
      </div>
      <section className="relative overflow-hidden bg-orange-500 sm:py-16 py-8 px-8 sm:mt-10 mt-5 z-[100] shadow-custom-orange">
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-wrap items-center">
            <div className="w-full px-4 lg:w-1/2">
              <div className="text-center lg:text-left">
                <div className=" lg:mb-0">
                  <h1 className="mt-0 sm:mb-3 mb-0 text-3xl font-bold leading-tight sm:text-4xl sm:leading-tight md:text-[40px] md:leading-tight text-white">
                    With our course, you'll learn:
                  </h1>
                  <p className="w-full text-base font-medium leading-relaxed sm:text-lg sm:leading-relaxed text-white">
                    {/* Add your text here */}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full px-4 lg:w-1/2 mt-6 lg:mt-0">
              <div className="text-center lg:text-right">
                <Link
                  to="/PaymentPage"
                  className="animate-linkglow font-semibold rounded-lg mx-auto inline-flex items-center justify-center bg-white py-4 px-9 hover:bg-opacity-90 cursor-pointer"
                >
                  Enroll Now
                </Link>
              </div>
            </div>
          </div>
        </div>
        <span className="absolute top-0 right-0 -z-10">
          <svg
            width="388"
            height="250"
            viewBox="0 0 388 220"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.05"
              d="M203 -28.5L4.87819e-05 250.5L881.5 250.5L881.5 -28.5002L203 -28.5Z"
              fill="url(#paint0_linear_971_6910)"
            ></path>
            <defs>
              <linearGradient
                id="paint0_linear_971_6910"
                x1="60.5"
                y1="111"
                x2="287"
                y2="111"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.520507" stopColor="white"></stop>
                <stop offset="1" stopColor="white" stopOpacity="0"></stop>
              </linearGradient>
            </defs>
          </svg>
        </span>
        <span className="absolute top-0 right-0 -z-10">
          <svg
            width="324"
            height="250"
            viewBox="0 0 324 220"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.05"
              d="M203 -28.5L4.87819e-05 250.5L881.5 250.5L881.5 -28.5002L203 -28.5Z"
              fill="url(#paint0_linear_971_6911)"
            ></path>
            <defs>
              <linearGradient
                id="paint0_linear_971_6911"
                x1="60.5"
                y1="111"
                x2="287"
                y2="111"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.520507" stopColor="white"></stop>
                <stop offset="1" stopColor="white" stopOpacity="0"></stop>
              </linearGradient>
            </defs>
          </svg>
        </span>
        <span className="absolute top-4 left-4 -z-10">
          <svg
            width="43"
            height="56"
            viewBox="0 0 43 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <circle
                cx="40.9984"
                cy="1.49626"
                r="1.49626"
                transform="rotate(90 40.9984 1.49626)"
                fill="white"
              ></circle>
              <circle
                cx="27.8304"
                cy="1.49626"
                r="1.49626"
                transform="rotate(90 27.8304 1.49626)"
                fill="white"
              ></circle>
              <circle
                cx="14.6644"
                cy="1.49626"
                r="1.49626"
                transform="rotate(90 14.6644 1.49626)"
                fill="white"
              ></circle>
              <circle
                cx="1.49642"
                cy="1.49626"
                r="1.49626"
                transform="rotate(90 1.49642 1.49626)"
                fill="white"
              ></circle>
              <circle
                cx="40.9984"
                cy="14.6643"
                r="1.49626"
                transform="rotate(90 40.9984 14.6643)"
                fill="white"
              ></circle>
              <circle
                cx="27.8304"
                cy="14.6643"
                r="1.49626"
                transform="rotate(90 27.8304 14.6643)"
                fill="white"
              ></circle>
              <circle
                cx="14.6644"
                cy="14.6643"
                r="1.49626"
                transform="rotate(90 14.6644 14.6643)"
                fill="white"
              ></circle>
              <circle
                cx="1.49642"
                cy="14.6643"
                r="1.49626"
                transform="rotate(90 1.49642 14.6643)"
                fill="white"
              ></circle>
              <circle
                cx="40.9984"
                cy="27.8324"
                r="1.49626"
                transform="rotate(90 40.9984 27.8324)"
                fill="white"
              ></circle>
              <circle
                cx="27.8304"
                cy="27.8324"
                r="1.49626"
                transform="rotate(90 27.8304 27.8324)"
                fill="white"
              ></circle>
              <circle
                cx="14.6644"
                cy="27.8324"
                r="1.49626"
                transform="rotate(90 14.6644 27.8324)"
                fill="white"
              ></circle>
              <circle
                cx="1.49642"
                cy="27.8324"
                r="1.49626"
                transform="rotate(90 1.49642 27.8324)"
                fill="white"
              ></circle>
              <circle
                cx="40.9984"
                cy="41.0004"
                r="1.49626"
                transform="rotate(90 40.9984 41.0004)"
                fill="white"
              ></circle>
              <circle
                cx="27.8304"
                cy="41.0004"
                r="1.49626"
                transform="rotate(90 27.8304 41.0004)"
                fill="white"
              ></circle>
              <circle
                cx="14.6644"
                cy="41.0004"
                r="1.49626"
                transform="rotate(90 14.6644 41.0004)"
                fill="white"
              ></circle>
              <circle
                cx="1.49642"
                cy="41.0004"
                r="1.49626"
                transform="rotate(90 1.49642 41.0004)"
                fill="white"
              ></circle>
              <circle
                cx="40.9984"
                cy="54.1686"
                r="1.49626"
                transform="rotate(90 40.9984 54.1686)"
                fill="white"
              ></circle>
              <circle
                cx="27.8304"
                cy="54.1686"
                r="1.49626"
                transform="rotate(90 27.8304 54.1686)"
                fill="white"
              ></circle>
              <circle
                cx="14.6644"
                cy="54.1686"
                r="1.49626"
                transform="rotate(90 14.6644 54.1686)"
                fill="white"
              ></circle>
              <circle
                cx="1.49642"
                cy="54.1686"
                r="1.49626"
                transform="rotate(90 1.49642 54.1686)"
                fill="white"
              ></circle>
            </g>
          </svg>
        </span>
      </section>
    </div>
  </div>
</section>

    </>
  );
}

export default Intro;
